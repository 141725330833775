<template>
  <ModalPortal>
    <ModalOverlay v-bind="emitAsProps">
      <slot></slot>
    </ModalOverlay>
  </ModalPortal>
</template>

<script setup lang="ts">
import { useEmitAsProps } from "radix-vue";
import type { Emits } from "~/components/modal/ModalOverlay.vue";

export type { Emits };

const emit = defineEmits<Emits>();

const emitAsProps = useEmitAsProps(emit);
</script>

<style scoped lang="scss"></style>
